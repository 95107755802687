<template>
  <div class="container">
    <div class="top">
      <div class="left-box">{{ this.title }}</div>
      <!-- <div class="right-box">时长：{{ this.duration }}</div> -->
    </div>
    <div class="video"><div id="test"></div></div>
    <div class="info-box">
      <img src="../assets/scan/01.jpg" alt="" />
      <img src="../assets/scan/02.jpg" alt="" />
      <img src="../assets/scan/03.jpg" alt="" />
      <img src="../assets/scan/04.jpg" alt="" style="margin-top: 20px;" />
    </div>
  </div>
</template>
<script>
import { getQrcode } from '../api/video'
export default {
  name: 'Home',
  data() {
    return {
      id: 1,
      title: '',
      duration: 0
    }
  },
  computed: {},
  created() {
    document.title = '教练员岗前培训与再教育'
    const element = document.getElementsByClassName('float-service')[0]
    element.setAttribute('style', 'display: none')
    let data = this.$route.query
    this.id = data.id
    this.getQrvideo()
    window.ccH5PlayerJsLoaded = true
    this.setPlayerCallBack()
  },
  methods: {
    getQrvideo() {
      getQrcode(this.id).then(res => {
        if (res.code == 0) {
          this.title = res.data.title
          this.duration = this.formatTime(res.data.duration)
          this.createPlayer(res.data.cc_id)
        }
      })
    },
    //秒数转分钟
    formatTime(totalSeconds) {
      var minutes = Math.floor(totalSeconds / 60)
      var seconds = totalSeconds % 60

      var formattedMinutes = minutes.toString().padStart(2, '0')
      var formattedSeconds = seconds.toString().padStart(2, '0')

      return formattedMinutes + ':' + formattedSeconds
    },
    createPlayer(vid) {
      this.video = document.getElementById('test')
      this.player = window.createCCH5Player({
        vid: vid,
        siteid: 'B3900E03A0A4C537',
        playtype: 1,
        width: '100%',
        height: '100%',
        isShare: false,
        banDrag: false,
        open_barrage: false,
        parentNode: this.video,
        autoStart: 'true',
        closeHistoryTime: 1,
        showRateBtn: false,
        showFullScreenBtn: true,
        openMobileLandscape: true
      })
    },
    setPlayerCallBack() {
      window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded
      window.on_CCH5player_ready = this.onPlayerReady
      window.on_player_volumechange = this.onPlayerVolumeChange
      window.on_CCH5player_pause = this.onCCH5playerpause
      window.on_CCH5player_play = this.onCCH5playerplay
      window.on_CCH5player_ended = this.onCCH5playerended
    }
  }
}
</script>
<style>
html,
body,
form,
input,
select,
textarea,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
img,
dl,
dt,
dd,
table,
th,
td,
blockquote,
fieldset,
div,
strong,
label,
em {
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  font-size: 14px;
  background-color: #01b05e;
}
#test {
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
  padding-bottom: 30px;
}
.top {
  width: 100%;
  padding: 20px 10px;
  color: #fff;
  box-sizing: border-box;
  background-color: #01b05e;
}
.left-box {
  width: 100%;
  display: inline-block;
  line-height: 18px;
}
.right-box {
  width: 28%;
  display: inline-block;
  text-align: right;
}
.video {
  width: 100%;
  text-align: center;
  background-color: #d7d7d7;
}
.info-box {
  width: 100%;
  font-size: 0;
}
.info-box img {
  width: 100%;
}
</style>
